
body {
    margin: 0;
    line-height: unset;
    padding: 0;
    background-color: white;
}


#hero-img {
    background-image: url('img/road-3309885.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 6px;
    height: 600px;
}

.hero_header {
    font-size: 90px;
    text-align: left;
    padding-left: 60px;
    padding-top: 200px;
}

.item__content {
    background-color: #f5f5f3;
    display: flex;
    flex-direction: row;
    padding: 0;
    color: #000;
    text-decoration: none;
    width: 400px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    transition: 0.3s ease;
}

.item__content:hover {
     background-color: rgba(0, 0, 0, 0.1);
}

.a_button {
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    margin-right: 10px;
    padding: 10px;
}

.arrow_button {
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    margin-right: 10px;
    text-decoration: none;
}

.a_button:hover {
    color: #000;
}

nav a {
    font-size: 24px;
    text-decoration: none;
    padding: 0;
}


    nav a:hover {
        color: #000;
    }

nav.subMeny {
    padding-top: 40px;
}

a.active {
    color: #000;
}

.table {
    margin: 50px 0 50px 20px;
    width: 80%;
}

table thead {
    font-size: 18px;
    border-bottom: 1px solid #BCBCC1;
}

table-striped {
    background-color: #f3f3f0;
}


.paginate_button {
    border: 1px solid #d2d2d2;
    padding: 8px 16px 8px 16px;
    background-color: #fff;
}

.paginate_active {
    border: 1px solid #d2d2d2;
    padding: 8px 16px 8px 16px;
    background-color: #f3f3f0;
}

a {
    color: #fff;
    box-shadow: unset !important;
}

    a:hover {
        color: #7a9b62 !important;
    }

.footer__column > a:hover {
    color: #9dd354 !important;
}


button, input, optgroup, select, textarea {
    padding-left: 10px;
}

.manual_img {
    width: 100px;
    padding-bottom: 40px;
    padding-top: 10px;
}

input[type="file"] {
    display: none;
}

/* Design of the toggle switches */
.card-checkbox.custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: #821307;
    border-color: transparent;
}

.card-checkbox.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #9dd354;
    border-color: transparent;
}
